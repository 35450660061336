<script>
import Avatar from "vue-avatar";
import { api } from "@/api/api.js";
import ProjectSelector from "./ProjectSelector.vue";
import { EventBus } from "@/components/EventBus.js";
import PfAddProjectIcon from '../icons/PfAddProjectIcon.vue';
import PfCloseProjectIcon from '../icons/PfCloseProjectIcon.vue';

export default {
  components: { Avatar, ProjectSelector, PfAddProjectIcon, PfCloseProjectIcon },
  props: {
    pId: {
      type: String,
      default: null,
    },
  },
  computed: {
    isSettingsTabActive() {
      return this.$store.getters.activeTab === "settings";
    },
    isProjectsTabActive() {
      return this.$store.getters.activeTab === "projects";
    },
    isPublishTabActive() {
      return this.$store.getters.activeTab === "publish";
    },
    getProjects() {
      return this.$store.getters.projects;
    },
    activeProject() {
      return this.$store.getters.activeProject;
    },
    userEmail() {
      const u = this.$store.getters.user;
      return u !== null
        ? u.emailAddress.substring(0, u.emailAddress.indexOf("@"))
        : "";
    },
    initial() {
      const u = this.$store.getters.user;
      if (u === null) {
        return "?";
      }
      const n = u.emailAddress;
      const i = n !== null ? n.substring(0, 1) : "?";
      return i;
    },
    getIconHeight() {
      const ratio = window.innerHeight / 100;
      // const css = getComputedStyle(document.documentElement).getPropertyValue(
      //   "--topnavbar-activetab-ht"
      // );
      // const num = parseInt(css.substring(0, css.indexOf("vh")));
      return (ratio * 6);
    },
    color() {
      const user = this.$store.getters.user;

      if (user) {
        const str = user.emailAddress;
        if (!str) {
          return "#000";
        }
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = "#";
        for (let i = 0; i < 3; i++) {
          let value = (hash >> (i * 8)) & 0xff;
          colour += ("00" + value.toString(16)).substr(-2);
        }
        return colour;
      } else {
        return "#000";
      }
    },
  },
  methods: {
    async signout() {
      await api.deleteCurrent().then((res) => {
        if (res.status === 204) {
          localStorage.removeItem("tm-user-token");
          // localStorage.removeItem("ngStorage-AuthenticationToken");
          this.$store.commit("setUser", null);
          this.$store.commit("setActiveProject", null);
          this.$store.commit("setActiveContext", null);
          this.$router.push({ name: "login", params: { from: "logout" } });
        }
      });
    },
    setActiveTab(tab) {
      this.$store.commit("setActiveTab", tab);
    },
    toggleProjectSelector() {
      EventBus.$emit("toggleProjectSelector");
    },
    setActiveProject(obj) {
      this.$store.commit("setActiveProject", obj);
    },
    clearActiveProject() {
      this.$store.commit("setActiveProject", null);
      EventBus.$emit("projectClosed", null);
    },
  },
  created() {
    api.getMe().then(
      (res) => {
        if (res.status !== 200 || !res.data.id) {
          this.$router.push({
            name: "login",
            params: { redirect: this.$route.path },
          });
        } else {
          this.$store.commit("setUser", res.data);
        }
      },
      () => {
        this.$router.push({ name: "login" });
      }
    );
  },
  data() {
    return {
      showList: false,
    };
  },
  destroyed() {
  },
};
</script>

<template>
  <div class="top-navbar">
    <b-container fluid>
      <b-row align-v="start" class="top-navbar-row">
        <b-col md="auto" cols="1" class="top-navbar-tab" style="padding: 0">
          <div style="float: left; padding-left: 1vh; margin-right: 0.5vw">
            <a class="profile-avatar" id="profile" variant="primary"
              ><Avatar
                :v-if="userEmail"
                :backgroundColor="color"
                color="#ffffff"
                :title="userEmail"
                :username="userEmail"
              />
            </a>
            <b-popover
              target="profile"
              placement="bottomright"
              :title="userEmail"
              triggers="click"
            >
              <a @click="signout" class="signout">Signout</a>
            </b-popover>
          </div>
          <img
            style="margin-right: 5px"
            class="navbar-logo"
            src="https://api.clay.work/download/resource:image/png,user-1e73d744-a310-4d03-83ee-04a4b420e7f4?Authentication-Token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJiMjMyMTQ1OC05MTAwLTQ2ZjgtODRkNS05NzEzNjAzMTljYTQiLCJpYXQiOjE3Mjc3OTQyNDMsImV4cCI6MTczMDIxMzQ0MywiYWNjb3VudElkIjoiYjczYmNhNDEtOTQ4OS00MDJmLWFkYzAtOTU4YjViMzMzYjRjIiwicm9sZXMiOltdLCJzdGF0dXMiOiJBY3RpdmUifQ.0qpOnOD_O-LGWGtl6l98VVKeJ2g_SNC4Nqe64htc1jM&Tenant=clay&qualifier=avatar"
          />
        </b-col>
        <b-col
          md="auto"
          @click="setActiveTab('projects')"
          :class="
            isProjectsTabActive ? 'top-navbar-tab active' : 'top-navbar-tab'
          "
        >
          <div class="label">
            <span class="text">PROJECTS</span>
            <pf-add-project-icon
              class="topbar-icon"
              @click="toggleProjectSelector"
              :size="getIconHeight"
            />
            <project-selector
              v-if="userEmail !== ''"
              :projects="getProjects"
              :activeProject="pId"
            />
          </div>
          <div class="user-selection" v-if="activeProject">
            <span class="text">{{ activeProject.text }}</span>
            <pf-close-project-icon
              class="topbar-icon"
              @click="clearActiveProject"
              :size="getIconHeight"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
.top-navbar {
  background: white;
  height: var(--topnavbar-ht);
}
.top-navbar-row {
  padding-bottom: 10px;
  background-color: #fff;
}
.top-navbar-tab {
  color: #888;
  cursor: pointer;
  height: var(--topnavbar-ht);
}
.top-navbar-tab:hover {
  background-color: var(--primary-bg);
  color: white;
}
.top-navbar-tab:first-child,
.top-navbar-tab:first-child:hover {
  background-color: transparent;
}
.top-navbar-tab,
.top-navbar-tab.active {
  background: #e9e9e9;
  margin-right: 5px;
  height: var(--topnavbar-ht);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* display: flex; */
}
.top-navbar-tab.active {
  background: var(--primary);
  color: #fff;
  height: var(--topnavbar-activetab-ht);
}
.top-navbar-tab .text {
  font-size: var(--fsize-topnavbar);
  color: inherit;
  font-weight: 400;
}

.top-navbar-tab.active .text {
  color: white;
  font-weight: 600;
  line-height: var(--topnavbar-activetab-ht);
}
.top-navbar-tab .label {
  display: inline-block;
}

.top-navbar-tab.active .label .topbar-icon {
  display: inline-block;
}
.top-navbar-tab .label,
.top-navbar-tab .user-selection {
  padding: 0 1vw;
}
.top-navbar-tab .user-selection,
.top-navbar-tab .label .topbar-icon {
  display: none;
  line-height: var(--topnavbar-activetab-ht);
}
.top-navbar-tab .user-selection {
  border-left: solid 1px white;
}

/* .top-navbar-tab.active .user-selection,
.top-navbar-tab.active .label .topbar-icon {
  display: inline-flex;
  flex-wrap: wrap;
} */
.top-navbar-tab.active .user-selection {
  padding-right: 0;
  display: inline;
}
.topbar-icon {
  padding: 0;
  color: white;
}
.top-navbar .material-design-icon svg {
  padding: 0.1vw;
}
.topbar-icon.plus-icon {
  margin-left: 1vw;
}

.topbar-icon.close-icon {
  margin-left: 1vw;
}

.navbar-logo,
.navbar-user {
  height: var(--topnavbar-ht);
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
}

.profile-avatar {
  cursor: pointer;
  line-height: var(--topnavbar-ht);
}
.vue-avatar--wrapper {
  height: var(--topnavbar-ht) !important;
  width: var(--topnavbar-ht) !important;
  font-size: var(--fsize-topnavbar)!important;
}
.signout {
  cursor: pointer;
  text-decoration: none;
  color: #000;
}
.signout:hover {
  color: var(--primary);
}
</style>
<style>
.popover {
  min-width: 220px;
}
.popover-header {
  text-transform: capitalize;
}
</style>
